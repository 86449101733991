import React from "react"
import styled from "styled-components"

const Button = ({text}) => {
  return (
    <StyledButton>
      <div className="outline">
        <svg
          className="icon"
          viewBox="0 0 262 84"
          preserveAspectRatio="none"
          
        >
          <g transform="translate(11.000000, 10.000000)">
            <g transform="translate(-11.000000, -10.000000)">
              <path
                fill="currentColor"
                d="M58.4247769,84 C18.6098705,84 11.7602748,75.9087835 7.66804223,71.0715776 L7.66804223,71.0715776 L7.35296829,70.6999496 C2.70329716,65.2403424 0,55.8499389 0,41.9969786 C0,28.1470398 2.70329716,18.7596576 7.35296829,13.297029 L7.35296829,13.297029 L7.66990657,12.9223797 C11.7621392,8.09121646 18.6098705,0 58.4247769,0 L58.4247769,0 L203.575223,0 C243.390129,0 250.237861,8.09121646 254.330093,12.9223797 L254.647032,13.297029 C259.296703,18.7596576 262,28.1470398 262,41.9969786 C262,55.8499389 259.296703,65.2403424 254.647032,70.6999496 L254.331958,71.0715776 C250.239725,75.9087835 243.390129,84 203.575223,84 Z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div className="shape">
        <svg
          className="icon icon-superellipse"
          viewBox="0 0 240 64"
          width="240"
          height="64"
          preserveAspectRatio="none"
        >
          <path
            d="M53.3955932,64 C17.0079396,64 10.7479546,57.8352637 7.00797992,54.1497734 L7.00797992,54.1497734 L6.72002743,53.8666283 C2.47059832,49.7069276 1.0658141e-13,42.5523344 1.0658141e-13,31.997698 C1.0658141e-13,21.4453636 2.47059832,14.2930724 6.72002743,10.1310697 L6.72002743,10.1310697 L7.00968378,9.84562262 C10.7496585,6.16473635 17.0079396,0 53.3955932,0 L53.3955932,0 L186.604407,0 C222.99206,0 229.250342,6.16473635 232.990316,9.84562262 L233.279973,10.1310697 C237.529402,14.2930724 240,21.4453636 240,31.997698 C240,42.5523344 237.529402,49.7069276 233.279973,53.8666283 L232.99202,54.1497734 C229.252045,57.8352637 222.99206,64 186.604407,64 Z"
          ></path>
        </svg>
      </div>
    <span className="content">{text}</span>
      <svg className="icon--arrow" viewBox="0 0 13 13" width="13" height="13">
        <g transform="translate(-206.000000, -25.000000)" fill="currentColor">
          <g transform="translate(204.000000, 24.000000)">
            <path d="M8.12132034,1 L12.48,5.359 L12.4818122,5.35843375 L14.6031325,7.47975409 L14.602,7.48 L14.6031325,7.48181216 L12.4818122,9.6031325 L12.48,9.602 L8.12132034,13.9615663 L6,11.8402459 L8.841,8.999 L2,9 L2,6 L8.878,5.999 L6,3.12132034 L8.12132034,1 Z"></path>
          </g>
        </g>
      </svg>
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.div`
  display: inline-block;
  margin-top: 40px;
  cursor: pointer;
  min-width: 150px;
  padding: 18px 30px 14px;
  user-select: none;
  position: relative;
  text-align: center;
  transition-delay: 1s;
  transition: transform 0.4s ease, opacity 0.4s ease;
  &[disabled] {
    cursor: default;
    opacity: 0.5
  }
  &:hover{
    .outline{
      transform: scaleX(1) scaleY(1);
      opacity: 1;
      transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    }
    .icon--arrow {
      transform: translate(5px, -50%)
    }
  }
  .icon--arrow {
    display: inline-block;
    vertical-align: middle;
    color: #f8f8f0;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translate(0px, -50%);
    transition: transform 0.2s ease 0s;
  }
  .shape {
    .icon {
      fill: #0fc7be;
      height: 100%;
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .outline{
    bottom: -10px;
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0;
    color: #4fe3d0;
    filter: drop-shadow(0px 5px 5px rgba(107,92,67,0.3));
    transform: scaleX(0.7) scaleY(0.7);
    transition: transform 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    .icon {
      height: 100%;
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .content{
    color: #fff;
    position: relative;
    top: -2px;
    display: block;
    margin-right: 20px;
  }

`