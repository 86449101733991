import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import Image from "@/components/Image"
import Button from "@/components/Button"

import useTranslation from "@/hooks/useTranslation"
import { Link } from "@/i18n"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      orville: file(relativePath: { eq: "page/orville.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const t = useTranslation()
  return (
    <Layout>
      <PageContainer>
        <SEO
          title={`404: ${t("seo.title")}`}
          description={`404: ${t("seo.description")}`}
        />
        <div className="notFound">
          <Image
            className="notFound_img"
            src={data.orville.childImageSharp.fluid}
          />
          <div className="notFound_info">
            <div className="notFound_info-text">
              <p>{t("notFound.title")}</p>
              <p>{t("notFound.tip")}</p>
            </div>
            <Link to="/">
              <Button text={t("notFound.btnText")} />
            </Link>
          </div>
        </div>
      </PageContainer>
    </Layout>
  )
}

export default NotFoundPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  display: flex;
  justify-content: center;
  align-items: center;
  .notFound {
    display: flex;
  }
  .notFound_img {
    width: 180px;
    margin-right: 40px;
  }
  .notFound_info {
    max-width: 240px;
    margin-top: 40px;
    .notFound_info-text{
      font-size: 18px;
      p {
        margin: 12px 0;
       }
    }
  }
`
